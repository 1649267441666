import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../counter-side/tier-list.scss';
import './index.scss';
import { Tabs, Tab } from 'react-bootstrap';

const AgTierListPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page characters-page-ag ag-page'}
      game="ag"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/artery-gear">Artery Gear</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/arterygear/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Tier list</h1>
          <h2>PVE and PVP tier list for Artery Gear Global.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="End of service" />
      <div className="banner eos">
        <div className="cta">
          <h4>Artery Gear - End of Service</h4>
          <p>
            Sadly, the dreaded day has come. On the 16th July 2024,{' '}
            <strong>
              Bilibili announced that Artery Gear will receive one last update
              and then the game will be put into maintenance mode
            </strong>{' '}
            - which means that within few months at most, the game will be
            closed down. You can read the note{' '}
            <a
              href="https://www.bilibili.com/opus/954322835327680513?spm_id_from=333.999.0.0"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            We will be reducing our support for the game and removing most of
            the content - we will only keep a snapshot of the last edition of
            our tier list. Thank you for the being with us!
          </p>
        </div>
      </div>
      <SectionHeader title="Tier list snapshot" />
      <p>
        The snapshot of the tier list was made on the{' '}
        <strong>16.07.2024</strong>, so the day the EoS was announced.
      </p>
      <Tabs
        defaultActiveKey="Team1"
        transition={false}
        id="boss-tabs"
        className="nav-tabs"
      >
        <Tab eventKey="Team1" title="PVE" className="with-bg">
          <h5>PVE Tier List</h5>
          <StaticImage src="../../images/arterygear/ag_pve.webp" alt="Guides" />
        </Tab>
        <Tab eventKey="Team2" title="PVP" className="with-bg">
          <h5>PVP Tier List</h5>
          <StaticImage src="../../images/arterygear/ag_pvp.webp" alt="Guides" />
        </Tab>
        <Tab eventKey="Team3" title="UA (Red)" className="with-bg">
          <h5>UA (Red) Tier List</h5>
          <StaticImage src="../../images/arterygear/ag_red.webp" alt="Guides" />
        </Tab>
        <Tab eventKey="Team4" title="UA (Blue)" className="with-bg">
          <h5>UA (Blue) Tier List</h5>
          <StaticImage
            src="../../images/arterygear/ag_blue.webp"
            alt="Guides"
          />
        </Tab>
        <Tab eventKey="Team5" title="UA (Green)" className="with-bg">
          <h5>UA (Green) Tier List</h5>
          <StaticImage
            src="../../images/arterygear/ag_green.webp"
            alt="Guides"
          />
        </Tab>
      </Tabs>
    </DashboardLayout>
  );
};

export default AgTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Artery Gear | Prydwen Institute"
    description="PVE and PVP tier list for Artery Gear: Fusion."
  />
);
